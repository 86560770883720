<template>
  <div class="home">
    <q-header active="0" />
    <div class="banner-panel">
      <q-img src="/img/bg1.png" class="banner-img" />
      <q-dot :width="200" :height="200" :left="20" :top="110" />
      <div class="content">
        <h2>
          LEVERAGED MARKETS <br />
          BASED ON BSC
        </h2>
        <div class="intro bg2 color-gradient">
          CREATE, BUY AND PARTICPATE IN LIQUIDITY TO EARN INCOME <br />
          STAKE YOUR EARNINGS AND EARN MORE
        </div>
        <div class="btn-panel app-flex">
          <div class="btn-item" @click="toLink(links[0])">
            <octagon bg="rgba(145, 64, 248, 1)" height="55px" width="240px">
              <div class="btn-content">
                <octagon
                  :bg="gradient[2]"
                  height="100%"
                  width="100%"
                  bg-inner="transparent"
                >
                  TRADE XPS NOW
                </octagon>
              </div>
            </octagon>
          </div>
          <div class="btn-item" @click="toLink(links[2])">
            <octagon bg="rgba(145, 64, 248, 1)" height="55px" width="240px">
              <div class="btn-content">
                <octagon
                  :bg="gradient[2]"
                  height="45px"
                  padding="2px"
                  width="230px"
                >
                  <span class="color-gradient bg3"> IMMEDIATELY PLEDGE </span>
                </octagon>
              </div>
            </octagon>
          </div>
        </div>
      </div>
    </div>
    <div class="swap-panel app-flex">
      <q-dot :width="200" :height="140" :left="80" :top="35" />

      <div class="title bg3 color-gradient">
        LOVED BY COMMUNITY <br />
        & PARTNERS
      </div>
      <octagon
        bg="rgba(145, 64, 248, 1)"
        bg-inner="#000"
        height="auto"
        width="1060px"
      >
        <div class="app-flex swap-flex">
          <div class="swap-item">
            <q-img src="/img/swap1.png" />
            <strong>PANCAKE SWAP</strong>
          </div>
          <div class="swap-item">
            <q-img src="/img/swap2.png" />
            <strong>TRUST WALLET</strong>
          </div>
          <div class="swap-item">
            <q-img src="/img/swap3.png" />
            <strong
              >BINANCE <br />
              <span>SMART CHAIN</span>
            </strong>
          </div>
          <div class="swap-item">
            <q-img src="/img/swap4.png" />
            <strong>COINMARKETCAP</strong>
          </div>
        </div>
      </octagon>
    </div>
    <div class="product-panel">
      <div class="product-item">
        <h2 class="text-center">
          ON THE MISSION TO BRING <br />
          X-PARALLEL SPACE TO THE MAINSTREAM
        </h2>

        <div class="logo text-center">
          <q-img src="/img/logo.png" width="40" /> <br />
          <strong> X-Parallel Space </strong>
        </div>
        <h3 class="color-gradient bg5 text-center">
          Is An Excess Lending Contract Built On BSC <br />
          With W With Wocus On Ease Of User Creation,Trading,And Participation
          In XPS
        </h3>
        <div class="app-flex parallel-space">
          <div class="item">
            <octagon
              width=" 420px"
              height="420px"
              class="c1"
              bg="rgba(145, 64, 248, 1)"
              bg-inner="#000"
            >
              <div class="parallel-content">
                <octagon bg="#24234c" class="c2">
                  <q-img src="/img/mission1.png" />
                  <div class="title">
                    Easily Create Leveraged Mines 122
                    <p>Use Your Funds To Get More XPS Token</p>
                  </div>
                  <div @click="toLink(links[2])">
                    <octagon
                      bg="#000"
                      bg-inner="#000"
                      width="340px"
                      height="50px"
                      class="btns btns1"
                    >
                      Join
                    </octagon>
                  </div>
                </octagon>
              </div>
            </octagon>
          </div>

          <div class="item">
            <octagon
              width=" 420px"
              height="420px"
              bg="rgba(145, 64, 248, 1)"
              bg-inner="#000"
              class="c1"
            >
              <div class="parallel-content">
                <octagon bg="#24234c" class="c2">
                  <q-img src="/img/mission2.png" />
                  <div class="title">
                    Easier Trading
                    <p>Easy Trading On Pancake swap</p>
                  </div>
                  <div @click="toLink(links[0])">
                    <octagon
                      bg="#000"
                      bg-inner="#000"
                      width="340px"
                      height="50px"
                      class="btns btns1"
                    >
                      Trading
                    </octagon>
                  </div>
                </octagon>
              </div>
            </octagon>
          </div>

          <div class="item">
            <octagon
              width=" 420px"
              height="420px"
              class="c1"
              bg="rgba(145, 64, 248, 1)"
              bg-inner="#000"
            >
              <div class="parallel-content">
                <octagon bg="#24234c" class="c2">
                  <q-img src="/img/mission3.png" />
                  <div class="title">
                    Easy Access Yo In-chain Data
                    <p>Check Your Transaction Data On BSC</p>
                  </div>
                  <div @click="toLink(links[1])">
                    <octagon
                      bg="#000"
                      bg-inner="#000"
                      width="340px"
                      height="50px"
                      class="btns btns1"
                    >
                      Inquiry
                    </octagon>
                  </div>
                </octagon>
              </div>
            </octagon>
          </div>
        </div>
      </div>
      <div class="product-item">
        <h2 class="text-center bor">
          What Is X-Parallel Space?
          <q-dot :width="200" :height="200" :top="-50" :right="30" />
        </h2>

        <h3 class="color-gradient bg5 text-center">
          X-Parallel Space is an over-lending contract to improve the efficiency
          of the use of funds. <br />
          Includes: leveraged mining, over borrowing and lending, run through
          smart contracts
          <q-dot
            :width="200"
            :height="100"
            :top="10"
            style="left: 75%; transform: translateX(-50%)"
          />
        </h3>
        <div class="app-flex parallel-space">
          <q-img src="/img/svgg.png" class="svvg" />
          <div class="act-law">
            <div class="hts">
              X-Parallel Space &nbsp;<span class="color-gradient bg3"
                >Model</span
              >
            </div>
            <octagon
              width="1032px"
              height="auto"
              bg="rgba(145, 64, 248, 1)"
              bg-inner="#000"
            >
              <div class="parallel-content">
                <octagon bg="#24234c">
                  <div class="act-panel">
                    <div
                      class="act-item"
                      v-for="(a, index) in actList"
                      :key="'actList' + index"
                    >
                      <div class="desc">
                        <q-img src="/img/hook.png" />
                        {{ a }}
                      </div>
                    </div>
                  </div>
                </octagon>
              </div>
            </octagon>
          </div>
        </div>
      </div>
      <div class="product-item">
        <h2 class="text-center">
          How Can I Participate In Leveraged <br />
          Mining With X-Parallel Space On BSC?
        </h2>

        <h3 class="color-gradient bg5 text-center">Follow These Three Steps</h3>
        <div class="app-flex parallel-space step-panel">
          <div class="item">
            <octagon
              width="160px"
              height="60px"
              class="step-btn"
              :bg="gradient[3]"
            >
              <div class="step-btn-txt">
                <span> step 1</span>
              </div>
            </octagon>
            <octagon
              width="420px"
              height="420px"
              bg="rgba(145, 64, 248, 1)"
              bg-inner="#000"
            >
              <div class="parallel-content text-center">
                <octagon bg="#24234c">
                  <q-img src="/img/step1.png" />
                  <div class="title1 bg4 color-gradient">
                    Connect Your Wallet
                  </div>
                  <div class="desc">
                    Use Trust Wallet Or Meta Mask To Connect To <br />
                    The App
                  </div>
                </octagon>
              </div>
            </octagon>
          </div>
          <div class="item">
            <octagon
              width="160px"
              height="60px"
              class="step-btn"
              :bg="gradient[3]"
            >
              <div class="step-btn-txt">
                <span> step 2</span>
              </div>
            </octagon>
            <octagon
              width="420px"
              height="420px"
              bg="rgba(145, 64, 248, 1)"
              bg-inner="#000"
            >
              <div class="parallel-content text-center">
                <octagon bg="#24234c">
                  <q-img src="/img/step2.png" />
                  <div class="title1 bg4 color-gradient">Pledged Assets</div>
                  <div class="desc">
                    Asset Pledging Through <br />
                    X-Parallel Space's Contracts
                  </div>
                </octagon>
              </div>
            </octagon>
          </div>

          <div class="item">
            <octagon
              width="160px"
              height="60px"
              class="step-btn"
              :bg="gradient[3]"
            >
              <div class="step-btn-txt"><span> step 3</span></div>
            </octagon>
            <octagon
              width=" 420px"
              height="420px"
              bg="rgba(145, 64, 248, 1)"
              bg-inner="#000"
            >
              <div class="parallel-content">
                <octagon bg="#24234c">
                  <q-img src="/img/step3.png" />
                  <div class="title1 bg4 color-gradient">Earn Revenue</div>
                  <div class="desc">
                    Pledged Assets For XPS Proceeds <br />
                    In exchange For USD Proceeds
                  </div>
                </octagon>
              </div>
            </octagon>
          </div>
        </div>
      </div>
    </div>
    <div class="purchase-panel app-flex">
      <div class="title bg3 color-gradient">The XPS Token</div>
      <div>
        <p>
          The pass economy is built around the XPS Token, a unit of value on the
          platform that enables pass holders to use applications on the
          platform, participate in network governance, and earn additional pass
          rewards for using the program
        </p>
        <div class="btn-panel app-flex">
          <div class="btn-item" @click="toLink(links[0])">
            <octagon bg="rgba(145, 64, 248, 1)" height="55px" width="240px">
              <div class="btn-content">
                <octagon
                  :bg="gradient[2]"
                  height="100%"
                  width="100%"
                  bg-inner="transparent"
                >
                  TRADE XPS NOW
                </octagon>
              </div>
            </octagon>
          </div>
        </div>
      </div>
    </div>
    <q-footer />
  </div>
</template>

<script>
import Octagon from '@/components/Octagon'
import QHeader from '@/components/QHeader'
import { gradient, links } from '@/data/config'

import QImg from '../components/QImg.vue'
import QDot from '../components/QDot'
import QFooter from '../components/QFooter.vue'

export default {
  name: 'Home',
  components: {
    Octagon,
    QHeader,
    QImg,
    QDot,
    QFooter
  },
  data () {
    return {
      links,
      gradient,
      active: 0,

      actList: [
        'Low Fee',
        'Security',
        'Foucs On User Experience',
        'Smart Contracts',
        'Leverage',
        'Leveraged Lending',
        'Capital Utilization Rate'
      ]
    }
  },
  methods: {
    toLink (link) {
      if (!link) return
      window.open(link)
    }
  }
}
</script>
<style lang="less">
@media (min-width: 768px) {
  body,
  html {
    min-width: 1440px;
  }
}
@media (max-width: 768px) {
  body,
  html {
    width: 100%;
    overflow-x: hidden;
  }
}

.home {
  width: 100%;
  .banner-panel {
    position: relative;
    background-color: rgba(16, 15, 33, 0.8);
    padding: 230px 100px 230px;
    &::before {
      z-index: 1;
      content: '';
      position: absolute;
      width: 852px;
      height: 650px;
      top: 138px;
      right: 0;
      border-radius: 20px 0px 0px 100px;
      background: linear-gradient(
        267.31deg,
        #994ff9 15.34%,
        #6f6dff 48.08%,
        #45d2ff 68.7%,
        #ff709e 84.04%,
        #7bffb2 101.13%
      );
    }
    &::after {
      z-index: 1;
      content: '';
      position: absolute;
      width: 850px;
      height: 648px;
      top: 139px;
      right: 1px;
      border-radius: 20px 0px 0px 100px;
      background-color: rgba(16, 15, 33, 1);
    }
    .content {
      position: relative;
      z-index: 3;
    }

    h2 {
      margin: 0;
      font-weight: 400;
      font-size: 80px;
      line-height: 90px;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 0px 5px 20px #170224;
    }
    .intro {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.4;
      text-transform: uppercase;
      margin: 80px 0;
    }

    .banner-img {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
    }
  }
  .btn-panel {
    .btn-item {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      &:first-child {
        margin-right: 20px;
        color: #000;
      }
      &::before {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        top: 0;
        left: 0;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABeSURBVHgBXcmhEkBAFIXhc+5uUTUzHsMbeZFd2UiyqCiKIGseQtZkBtcOzR+/n303a7tmmi5ELGCywYmVE0olAB6Ezxt6a8wFiiKAK0r6MCFib7XR7uvqg7dxGhx+Pe4bG3gm/vQwAAAAAElFTkSuQmCC');
      }
      .btn-content {
        padding: 5px;
        height: 100%;
        width: 100%;
        .inner {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .swap-panel {
    position: relative;
    background: #000;
    padding: 45px 60px;
    .title {
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      margin-right: 40px;
    }
    .swap-flex {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      padding: 4px 20px 4px 4px;
      font-weight: bold;
      .swap-item {
        display: flex;
        align-items: center;
        line-height: 1;
        img {
          flex-shrink: 0;
          margin-right: 5px;
        }
        span {
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
  .product-panel {
    background: rgba(16, 15, 33, 0.8);
    .product-item {
      padding-top: 150px;
      h2 {
        position: relative;
        margin: 0;
        font-size: 50px;
        line-height: 60px;
        &.bor {
          &::before {
            position: absolute;
            left: 50%;
            bottom: 2px;
            transform: translateX(-50%);
            content: '';
            width: 1140px;
            height: 1px;

            background: linear-gradient(
              266.33deg,
              #d0076d 3.3%,
              #7211b6 75.16%
            );
            border-left: 3px solid #fff;
            border-right: 3px solid #fff;
          }
        }
      }
      .logo {
        font-size: 24px;
        margin-top: 40px;
      }
      h3 {
        position: relative;
        margin: 40px 0 70px;
        font-weight: bold;
        font-size: 22px;
      }
      .parallel-space {
        justify-content: center;
        align-items: center;

        .parallel-content {
          height: 100%;
          padding: 10px;
          .inner {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }
        .item {
          margin: 0 9px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 0;
            border-color: #d81fd2 transparent transparent transparent;
          }
          .c1:hover,
          .c1:hover .inner {
            background: linear-gradient(
              141.43deg,
              #d71ed1 -0.18%,
              #1219d3 88.06%
            ) !important;
          }
          .c2:hover,
          .c2:hover .inner {
            background: transparent !important;
          }

          .title {
            font-size: 18px;
            line-height: 35px;
            margin: 25px 0 20px;
            font-weight: bold;
            p {
              font-size: 12px;
              margin: 0;
              line-height: 35px;
            }
          }
          .title1 {
            margin: 38px 0 10px;
            font-size: 26px;
          }
          .desc {
            font-size: 16px;
            font-weight: bold;
          }
          .btns {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
            font-weight: bold;
            font-size: 18px;
            margin: 0 auto;
            line-height: 50px;
            text-align: center;
          }
        }
      }
      img.svvg {
        position: relative;
        z-index: 2;
        display: block;
        margin-right: 10px;
        flex-shrink: 0;
      }
      .act-law {
        position: relative;
        &::before {
          content: '';
          right: 30px;
          top: -40px;
          left: -120px;
          bottom: 40px;
          border-radius: 0 0 0 80px;
          background: #000;
          position: absolute;
        }
        .hts {
          position: relative;
          font-size: 34px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .act-panel {
          display: flex;
          flex-wrap: wrap;
          font-size: 22px;
          padding: 68px 35px;
          .act-item {
            width: 50%;
            padding: 0 34px;
            line-height: 58px;
            .desc {
              border-bottom: 1px dashed;
              border-image-source: linear-gradient(to right, #8f41e9, #578aef);
              border-image-slice: 2;
              border-image-source: linear-gradient(
                267.31deg,
                #994ff9 15.34%,
                #6f6dff 48.08%,
                #45d2ff 68.7%,
                #ff709e 84.04%,
                #7bffb2 101.13%
              );
            }
          }
        }
      }
      .step-btn {
        position: absolute;
        top: -30px;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        .inner {
          overflow: hidden;
        }
        .step-btn-txt {
          line-height: 60px;
          text-align: center;
          font-size: 25px;
          background: rgba(196, 196, 196, 0.2);
          span {
            font-weight: bolder;
            background: linear-gradient(95.58deg, #ff876a 0%, #ffeaa2 97.2%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }
    }
  }
  .purchase-panel {
    justify-content: center;
    align-items: center;
    margin-top: 160px;
    padding: 94px 15px 88px;
    background-image: url('/img/bg2.png');
    background-size: cover;
    background-position: center;
    border-bottom: 1px dashed;
    border-top: 1px dashed;
    border-left: 0;
    border-right: 0;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      267.31deg,
      #994ff9 15.34%,
      #6f6dff 48.08%,
      #45d2ff 68.7%,
      #ff709e 84.04%,
      #7bffb2 101.13%
    );
    .title {
      font-size: 50px;
      line-height: 60px;
      font-weight: bold;
      margin-right: 80px;
      margin-bottom: 25px;
    }
    p {
      font-size: 16px;
      line-height: 25px;
      max-width: 65vw;
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 768px) {
  .home {
    br,
    .dot {
      display: none;
    }
    .banner-panel {
      padding: 0.6rem 0.3rem 1rem;
      &::before {
        background: none;
      }
      h2 {
        font-size: 0.36rem;
        line-height: 1.4;
      }
      .intro {
        margin: 0.4rem 0;
        font-size: 0.24rem;
      }
      .banner-img {
        width: 30%;
      }
      .btn-panel {
        .btn-item,
        .octagon-panel {
          width: 100% !important;
        }
        .btn-item:first-child {
          margin: 0 0 0.45rem 0;
        }
      }
    }
    .swap-panel {
      position: relative;
      z-index: 10;
      padding: 0.2rem 0.3rem;
      .title {
        font-size: 0.32rem;
        text-align: center;
        margin: 0 0 0.6rem;
      }
      .octagon-panel {
        .swap-flex {
          padding: 0.3rem;
        }
        .swap-item {
          width: 100%;
          margin-bottom: 0.2rem;
        }
      }
    }
    .product-panel {
      .product-item {
        position: relative;
        z-index: 10;
        padding-top: 0.6rem;
        h2 {
          font-size: 0.42rem;
          padding: 0 0.2rem;
          line-height: 1.4;
          &.bor {
            &:before {
              width: 80%;
            }
          }
        }
        .logo {
          font-size: 0.28rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.4rem;
        }
        h3 {
          margin: 0.3rem 0 0.6rem;
          font-size: 0.24rem;
          padding: 0 0.3rem;
        }
        .parallel-space {
          margin: 0 0.3rem;
          display: block;
          &.step-panel {
            .item {
              margin-top: 1rem;
            }
          }
          .item {
            margin: 0 0 0.3rem 0;
            img {
              width: 80%;
            }
          }
          .octagon-panel {
            width: 100% !important;
            &.btns {
              width: 80% !important;
              &.btns1 {
                width: 200px !important;
              }
            }
          }
          img.svvg {
            width: 0.6rem;
            margin: 0 auto 0.8rem;
          }
          .act-law {
            &::before {
              background: none;
            }
            .hts {
              position: absolute;
              left: 0.8rem;
              top: -0.7rem;
              width: 84%;
              text-align: center;
              z-index: 10;
              font-size: 0.28rem;
            }
            .act-panel {
              padding: 0.2rem;
              font-size: 0.24rem;
              img {
                vertical-align: middle;
              }
            }
            .act-item {
              padding: 0 0.15rem;
              width: 100%;
            }
          }
          .step-btn {
            width: 50% !important;
            height: 50px !important;
            .step-btn-txt {
              line-height: 50px;
            }
          }
          .title1 {
            font-size: 0.32rem;
          }
          .desc {
            font-size: 0.28rem;
            text-align: center;
          }
        }
      }
    }
    .purchase-panel {
      margin-top: 1.2rem;
      padding: 0.45rem 0.3rem;
      display: block;

      .title {
        margin: 0;
        font-size: 0.42rem;
      }
      p {
        max-width: 100%;
        text-align: justify;
      }
      .btn-panel {
        display: block;
        margin-top: 0.6rem;
        .btn-item {
          margin: 0;
        }
      }
      .octagon-panel {
        width: 100% !important;
      }
    }
  }
}
</style>
