<template>
  <div class="app-flex site-footer">
    <div class="logo-panel app-flex">
      <q-img src="/img/logo.png" width="40" />
      <strong>
        X-Parallel <br />
        Space
      </strong>
    </div>
    <div class="nav-right app-flex">
      <p>info@X_Parallel_Space.com</p>
      <a
        href="https://github.com/xpslab"
        target="_blank"
        alt="Github"
        title="Github"
      >
        <q-img src="/img/bird1.svg" />
      </a>
      <a
        href="https://twitter.com/X_Parall"
        target="_blank"
        alt="Twitter"
        title="Twitter"
      >
        <q-img src="/img/bird2.svg" />
      </a>
      <a
        href="https://lunaray.co/projectdetails"
        target="_blank"
        alt="Audit"
        title="Audit"
      >
        <q-img src="/img/bird3.svg" />
      </a>
    </div>
  </div>
</template>
<script>
import QImg from './QImg'
import { gradient } from '@/data/config'
export default {
  components: {
    QImg
  },
  props: ['active'],
  data () {
    return {
      gradient,
      navlist: [
        { title: 'HOME' },
        { title: 'TRADE XPS' },
        { title: 'EARN' },
        { title: 'WHITE PAPER' }
      ]
    }
  }
}
</script>
<style lang="less">
.site-footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 70px;

  .logo-panel {
    align-items: center;
    font-size: 18px;
    strong {
      margin-left: 10px;
      line-height: 17px;
    }
  }

  .nav-right {
    align-items: center;
    p {
      margin: 0 42px 0 0;
      font-size: 14px;
    }
    a {
      display: flex;
      align-items: center;
    }
    img {
      margin: 0 8px;
      width: 32px;
    }
  }
}
@media (max-width: 768px) {
  .site-footer {
    padding: 0.6rem 0.3rem;
    display: block;
    text-align: center;
    .logo-panel {
      justify-content: center;
    }
    .nav-right {
      justify-content: center;
      p {
        width: 100%;
        margin: 0.3rem 0;
      }
    }
  }
}
</style>
