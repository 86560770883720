<template>
  <div
    :class="{ 'octagon-panel': !small, 'octagon-small-panel': small }"
    :style="{ height, width, background: bg, padding }"
  >
    <div class="inner" :style="{ background: bgInner }">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    bg: {
      type: String,
      default: 'transparent'
    },
    bgInner: {
      type: String,
      default: 'rgb(16, 15, 33)'
    },
    padding: {
      type: String,
      default: '1px'
    },

    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="less" scoped>
.octagon-panel {
  transition: all 0.1s;
  cursor: pointer;
  padding: 1px;
  -webkit-clip-path: polygon(
    12px 0,
    calc(100% - 12px) 0,
    100% 8px,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    8px 100%,
    0 calc(100% - 8px),
    0 12px
  );
  clip-path: polygon(
    12px 0,
    calc(100% - 12px) 0,
    100% 8px,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    8px 100%,
    0 calc(100% - 8px),
    0 12px
  );

  .inner {
    width: 100%;
    height: 100%;
    -webkit-clip-path: polygon(
      12px 0,
      calc(100% - 12px) 0,
      100% 8px,
      100% calc(100% - 12px),
      calc(100% - 12px) 100%,
      8px 100%,
      0 calc(100% - 8px),
      0 12px
    );
    clip-path: polygon(
      12px 0,
      calc(100% - 12px) 0,
      100% 8px,
      100% calc(100% - 12px),
      calc(100% - 12px) 100%,
      8px 100%,
      0 calc(100% - 8px),
      0 12px
    );
  }
}
.octagon-small-panel {
  cursor: pointer;
  padding: 1px;
  -webkit-clip-path: polygon(
    8px 0,
    calc(100% - 7px) 0,
    100% 5px,
    100% calc(100% - 9px),
    calc(100% - 9px) 100%,
    7px 100%,
    0 calc(100% - 5px),
    0 9px
  );
  clip-path: polygon(
    8px 0,
    calc(100% - 7px) 0,
    100% 5px,
    100% calc(100% - 9px),
    calc(100% - 9px) 100%,
    7px 100%,
    0 calc(100% - 5px),
    0 9px
  );
  .inner {
    width: 100%;
    height: 100%;
    -webkit-clip-path: polygon(
      8px 0,
      calc(100% - 7px) 0,
      100% 5px,
      100% calc(100% - 9px),
      calc(100% - 9px) 100%,
      7px 100%,
      0 calc(100% - 5px),
      0 8px
    );
    clip-path: polygon(
      8px 0,
      calc(100% - 7px) 0,
      100% 5px,
      100% calc(100% - 9px),
      calc(100% - 9px) 100%,
      7px 100%,
      0 calc(100% - 5px),
      0 8px
    );
  }
}
</style>
