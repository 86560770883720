<template>
  <div
    class="dot"
    :style="{
      width: width + 'px',
      height: height + 'px',
      left: left ? left + 'px' : '',
      right: right ? right + 'px' : '',
      top: top + 'px'
    }"
  ></div>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    right: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="less" scoped>
.dot {
  position: absolute;
  background-image: url('/img/dot.png');
  background-repeat: repeat;
}
</style>
