<template>
  <div>
    <div class="app-flex app-flex-end site-topbar">
      <div class="header-logo app-flex">
        <q-img src="/img/logo.png" width="40" />
        <strong> X-Parallel Space </strong>
      </div>
      <div class="title">
        <strong class="color-gradient bg1">BSC XPS Token Contract:</strong>
        <span>0x8e9b87cad37610d60120a1f48aa1036e24a3831a</span>
      </div>
      <div class="buy-btn" @click="toLink(links[0])">
        <octagon
          class="text-center"
          small
          width="185px"
          height="30px"
          :bg="gradient[0]"
          :bg-inner="gradient[1]"
        >
          buy xps & earn
        </octagon>
      </div>
      <div class="menuanchor-btn" @click="show = true">
        <q-img src="/img/menuanchor.png" />
      </div>
    </div>
    <div class="app-flex site-header">
      <div class="header-logo app-flex">
        <q-img src="/img/logo.png" width="40" />
        <strong>
          X-Parallel <br />
          Space
        </strong>
      </div>
      <div class="nav-list app-flex">
        <div
          class="item"
          v-for="(v, index) in navlist"
          :key="'keys' + index"
          @click="toNav(v)"
        >
          <octagon
            class="text-center"
            width="135px"
            height="30px"
            small
            :bg="active == index ? gradient[2] : ''"
            :bg-inner="active == index ? 'rgb(16, 15, 33)' : ''"
          >
            {{ v.title }}</octagon
          >
        </div>
      </div>
      <div class="nav-right">
        <a
          href="https://github.com/xpslab"
          target="_blank"
          alt="Github"
          title="Github"
        >
          <q-img src="/img/bird1.svg" />
        </a>
        <a
          href="https://twitter.com/X_Parall"
          target="_blank"
          alt="Twitter"
          title="Twitter"
        >
          <q-img src="/img/bird2.svg" />
        </a>
        <a
          href="https://lunaray.co/projectdetails"
          target="_blank"
          alt="Audit"
          title="Audit"
        >
          <q-img src="/img/bird3.svg" />
        </a>
      </div>
    </div>
    <van-popup
      get-container="body"
      v-model="show"
      position="right"
      lock-scroll
      class="popup-nav"
      :style="{ height: '100%', width: '70%' }"
    >
      <div class="header-logo">
        <q-img src="/img/logo.png" width="40" />
        <p>X-Parallel Space</p>
      </div>
      <div class="nav-panel">
        <octagon :bg="gradient[2]">
          <div
            class="nav-item color-gradient bg2"
            v-for="(v, index) in navlist"
            :key="'keys' + index"
            @click="toNav(v)"
          >
            {{ v.title }}
          </div>
        </octagon>
      </div>
      <div class="nav-footer">
        <a
          href="https://github.com/xpslab"
          target="_blank"
          alt="Github"
          title="Github"
        >
          <q-img src="/img/bird1.svg" />
          <div class="title">Github</div>
        </a>
        <a
          href="https://twitter.com/X_Parall"
          target="_blank"
          alt="Twitter"
          title="Twitter"
        >
          <q-img src="/img/bird2.svg" />
          <div class="title">Twitter</div>
        </a>
        <a
          href="https://lunaray.co/projectdetails"
          target="_blank"
          alt="Audit"
          title="Audit"
        >
          <q-img src="/img/bird3.svg" />
          <div class="title">Audit</div>
        </a>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Octagon from './Octagon'
import QImg from './QImg'
import { gradient, links } from '@/data/config'
import { Popup } from 'vant'

export default {
  components: {
    Octagon,
    QImg,
    [Popup.name]: Popup
  },
  props: ['active'],
  data () {
    return {
      gradient,
      navlist: [
        { title: 'HOME' },
        { title: 'TRADE XPS', link: links[0] },
        { title: 'EARN', link: links[1] },
        { title: 'WHITE PAPER', link: links[2] }
      ],
      show: false,
      links
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeHandler)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  methods: {
    resizeHandler () {
      this.show = false
    },
    toLink (link) {
      if (!link) return
      window.open(link)
    },
    toNav (item) {
      if (item.link) {
        window.open(item.link)
      } else {
        this.show = false
      }
    }
  }
}
</script>
<style lang="less">
.site-topbar {
  padding: 20px 50px;
  justify-content: flex-end;
  line-height: 30px;
  font-size: 15px;
  background: #000;
  .title {
    margin-right: 50px;
    strong {
      font-size: 16px;
      margin-right: 10px;
    }
    span {
      font-size: 12px;
      text-transform: lowercase;
      color: #5c5f76;
    }
  }
  .buy-btn {
    .inner {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
    }
  }
  .header-logo {
    display: none;
  }
  .menuanchor-btn {
    display: none;
  }
}
.site-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
  background: rgb(16, 15, 33);
  backdrop-filter: blur(40px);
  border-bottom: 1px dashed;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    267.31deg,
    #994ff9 15.34%,
    #6f6dff 48.08%,
    #45d2ff 68.7%,
    #ff709e 84.04%,
    #7bffb2 101.13%
  );

  .header-logo {
    align-items: center;
    font-size: 18px;
    strong {
      margin-left: 10px;
      line-height: 17px;
    }
    img {
      display: block;
    }
  }
  .nav-list {
    font-size: 15px;
    .item {
      margin-right: 40px;
      line-height: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .nav-right {
    flex-shrink: 0;
    display: flex;
    a {
      display: flex;
      align-items: center;
    }
    img {
      margin: 0 8px;
      width: 28px;
      vertical-align: middle;
    }
  }
}
@media (max-width: 768px) {
  .site-topbar {
    position: relative;
    padding: 0.2rem 0.3rem;
    display: block;
    .title {
      line-height: 1.2;
      word-break: break-all;
      margin: 0.1rem 0 0.2rem;
      strong {
        display: block;
        font-size: 0.28rem;
      }
      span {
        display: block;
        font-size: 0.24rem;
      }
    }
    .buy-btn {
      flex-shrink: 0;
      width: 2.5rem !important;
      margin-right: 0.1rem;
    }
    .menuanchor-btn {
      display: block;
      width: 50px;
      position: absolute;
      top: 0.2rem;
      right: 0;
      text-align: center;
      height: 30px;
      img {
        vertical-align: middle;
      }
    }
    .header-logo {
      display: block;

      img {
        width: 0.5rem;
      }
    }
  }
  .site-header {
    display: none;
  }
  .popup-nav {
    background: none;
    padding: 0.8rem 0.25rem 0.3rem;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    .header-logo {
      img {
        display: block;
      }
      p {
        font-size: 0.34rem;
        margin: 0.2rem 0;
      }
    }
    .nav-panel {
      flex: 1;
      .inner {
        padding: 0.6rem 0.3rem;
      }
      .nav-item {
        font-size: 0.32rem;
        font-weight: bold;
        padding: 0.2rem 0;

        border-bottom: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(
          270deg,
          #00e0b8 0%,
          #006ce6 96.35%
        );
      }
    }
    .nav-footer {
      display: flex;
      justify-content: space-between;
      padding: 0.3rem 0.1rem 0;
      text-align: center;
      a {
        padding: 0 0.15rem;
      }
      .title {
        font-size: 0.3rem;
        color: #fff;
      }
      img {
        width: 0.5rem;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>
