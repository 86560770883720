export const gradient = {
  0: 'linear-gradient(266.62deg, #F92EAD 6.3%, #8B17DE 41.36%)',
  1: 'linear-gradient(266.33deg, #D0076D 3.3%, #7211B6 75.16%)',
  2: 'linear-gradient(266.85deg, #A45CFF 10.35%, #8277FF 28.72%, #6F6DFF 53.92%, #45A6FF 73.92%, #E3A1B6 88.58%, #7BFFB2 102.22%)',
  3: 'linear-gradient(95.58deg, #FF876A 0%, #FFEAA2 97.2%)'
}

export const links = {
  0: 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x8E9b87caD37610D60120A1f48AA1036e24a3831a',
  1: 'https://bscscan.com/address/0x8E9b87caD37610D60120A1f48AA1036e24a3831a',
  2: 'https://dapp.xpslab.pro/#/'
}
