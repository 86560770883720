<template>
  <img :src="baseurl+srcval" v-if="srcval">
</template>
<script>
export default {
  props: ['src'],
  computed: {
    baseurl () {
      return process.env.NODE_ENV === 'production' ? process.env.BASE_URL : '/'
    },
    srcval () {
      let val = ''
      if (this.src) {
        val = this.src.slice(1)
      }
      return val
    }
  }

}
</script>
